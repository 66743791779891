import { useState, useEffect } from 'react';
import * as shiftService from '../services/shiftService';
import { format, parseISO } from 'date-fns';

export const transformShiftData = (shifts, setScheduleStatus) => {
    let allApproved = shifts.length > 0;
    const transformedData = shifts.reduce((acc, shift) => {
        if (!shift.date_of_shift) {
            console.warn('Shift has no date:', shift);
            return acc;
        }

        const userId = shift.user_id;
        let dayOfWeek;
        try {
            const dateObject = new Date(shift.date_of_shift);
            if (isNaN(dateObject.getTime())) {
                throw new Error('Invalid date');
            }
            dayOfWeek = format(dateObject, "EEE d");
        } catch (error) {
            console.error('Error parsing date:', shift.date_of_shift, error);
            return acc;
        }

        if (!acc[userId]) acc[userId] = {};
        if (!acc[userId][dayOfWeek]) acc[userId][dayOfWeek] = [];

        acc[userId][dayOfWeek].push({
            id: shift.id,
            name: shift.shift?.name || "No shift",
            color: shift.shift?.color_code || "#000000",
            status: shift.status,
            calculated_shift_time: shift.shift.calculated_shift_time || 0,
            slack_time: shift.shift.slack_time || 0,
        });

        if (shift.status !== "approved") {
            allApproved = false;
        }
        return acc;
    }, {});

    setScheduleStatus(allApproved ? 'approved' : 'draft');
    return transformedData;
};

export const transformLeaveData = (leaves) => {
    return leaves.reduce((acc, leave) => {
        const userId = leave.user_id;
        const dayOfWeek = format(parseISO(leave.start_date), "EEE d");
        if (!acc[userId]) acc[userId] = {};
        if (!acc[userId][dayOfWeek]) acc[userId][dayOfWeek] = [];
        acc[userId][dayOfWeek].push({
            id: leave.id,
            title: leave.leave_type.title,
            color: leave.leave_type.color_code,
            type: leave.leave_type.name,
            start_date: leave.start_date,
            end_date: leave.end_date,
            status: leave.status
        });
        return acc;
    }, {});
};

export const useShiftData = (branchId, currentWeekDate, setScheduleStatus) => {
    const [shiftData, setShiftData] = useState({});
    const [leaveData, setLeaveData] = useState({});
    const [holidayData, setHolidayData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    const transformHolidayData = (holidays) => {
        return holidays.reduce((acc, holiday) => {
            const startDate = new Date(holiday.start_date);
            const endDate = new Date(holiday.end_date);

            // UTC tarihlerini yerel tarihe çevir
            const localStartDate = new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate());
            const localEndDate = new Date(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate());

            for (let date = new Date(localStartDate); date <= localEndDate; date.setDate(date.getDate() + 1)) {
                const formattedDate = format(date, "yyyy-MM-dd");
                if (!acc[formattedDate]) acc[formattedDate] = [];
                acc[formattedDate].push({
                    id: holiday.id,
                    title: holiday.title,
                    color_code: holiday.color_code,
                    start_date: localStartDate,
                    end_date: localEndDate,
                    is_half_day: holiday.is_half_day,
                    is_web_shift: holiday.is_web_shift,
                    holiday_calendar_id: holiday.holiday_calendar_id
                });
            }
            return acc;
        }, {});
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!branchId) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            try {
                const data = await shiftService.fetchShiftData(currentWeekDate, branchId);
                setShiftData(transformShiftData(data.shifts, setScheduleStatus));
                setLeaveData(transformLeaveData(data.leaves));
                setHolidayData(transformHolidayData(data.holidays));
                setError(null);
            } catch (err) {
                console.error("Error fetching shift data:", err);
                setError("Vardiya verileri yüklenirken bir hata oluştu.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [branchId, currentWeekDate, setScheduleStatus]);

    return { shiftData, setShiftData, leaveData, setLeaveData, holidayData, isLoading, error };

};

