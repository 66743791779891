import api from './api';

export const fetchWaterIntakes = async (startDate, endDate) => {
    try {
        const response = await api.get('/water_intakes', {
            params: { start_date: startDate, end_date: endDate }
        });
        return response.data.water_intakes || [];
    } catch (error) {
        console.error('Error fetching water intakes:', error);
        return [];
    }
};

export const addWaterIntake = async (data) => {
    try {
        const response = await api.post('/water_intakes', {
            water_intake: {
                amount: parseInt(data.amount),
                intake_at: data.date.toISOString(),
                notes: data.notes || ''
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error adding water intake:', error);
        throw error;
    }
};

export const updateWaterIntake = async (id, data) => {
    try {
        const response = await api.put(`/water_intakes/${id}`, {
            water_intake: {
                amount: data.amount,
                intake_at: data.date,
                notes: data.notes
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating water intake:', error);
        throw error;
    }
};

export const deleteWaterIntake = async (id) => {
    try {
        await api.delete(`/water_intakes/${id}`);
    } catch (error) {
        console.error('Error deleting water intake:', error);
        throw error;
    }
};