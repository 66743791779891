import { useState, useEffect } from 'react';
import { fetchWaterIntakes, addWaterIntake } from '../services/waterTrackerService';

export const useWaterTracker = () => {
    const [waterData, setWaterData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const DEFAULT_GOAL = 2000; // 2L in ml

    const loadWaterIntakes = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const data = await fetchWaterIntakes();

            // Günlük toplam su tüketimlerini hesapla
            const formattedData = data.reduce((acc, intake) => {
                const dateKey = new Date(intake.intake_at).toISOString().split('T')[0];
                acc[dateKey] = (acc[dateKey] || 0) + intake.amount;
                return acc;
            }, {});

            setWaterData(formattedData);
            console.log('Formatted water data:', formattedData); // Debug için
        } catch (err) {
            console.error('Error in loadWaterIntakes:', err);
            setError(null);
            setWaterData({});
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadWaterIntakes();

        // Her 5 dakikada bir güncelle
        const interval = setInterval(loadWaterIntakes, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    const handleAddWater = async (date, amount, notes = '') => {
        try {
            await addWaterIntake({ date, amount, notes });

            // State'i hemen güncelle
            const dateKey = date.toISOString().split('T')[0];
            setWaterData(prev => ({
                ...prev,
                [dateKey]: (prev[dateKey] || 0) + amount
            }));

            // Tüm su tüketim verilerini yeniden yükle
            await loadWaterIntakes();
            return true;
        } catch (err) {
            console.error('Error in handleAddWater:', err);
            return false;
        }
    };

    const getWaterProgress = (dateStr) => {
        const amount = waterData[dateStr] || 0;
        return Math.min((amount / DEFAULT_GOAL) * 100, 100);
    };

    const getTodayWater = () => {
        const today = new Date().toISOString().split('T')[0];
        return waterData[today] || 0;
    };

    const getProgressColor = (percentage) => {
        if (percentage >= 80) return 'success';
        if (percentage >= 50) return 'warning';
        return 'danger';
    };

    return {
        waterData,
        isLoading,
        error,
        handleAddWater,
        getWaterProgress,
        getTodayWater,
        getProgressColor,
        DEFAULT_GOAL
    };
};