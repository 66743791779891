import React, { useState, useEffect } from 'react';
import './EventModal.css';

const EventModal = ({ isOpen, onClose, event, selectedDate, onAdd, onUpdate, onDelete }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        start: '',
        end: '',
        allDay: false,
        color: '#FD7014'
    });

    useEffect(() => {
        if (event) {
            const startDate = event.start ? formatDateToLocal(event.start) : '';
            const endDate = event.end ? formatDateToLocal(event.end) : '';

            setFormData({
                title: event.title,
                description: event.extendedProps?.description || '',
                start: startDate,
                end: endDate,
                allDay: event.allDay,
                color: event.backgroundColor || '#FD7014'
            });
        } else if (selectedDate) {
            const start = new Date(selectedDate.start || selectedDate.startStr);
            const end = new Date(selectedDate.end || selectedDate.endStr || start);

            if (start && !isNaN(start.getTime()) && end && !isNaN(end.getTime())) {
                setFormData(prev => ({
                    ...prev,
                    start: formatDateToLocal(start),
                    end: formatDateToLocal(end),
                    allDay: selectedDate.allDay || false
                }));
            }
        }
    }, [event, selectedDate]);

    const formatDateToLocal = (date) => {
        if (!date || !(date instanceof Date)) {
            date = new Date(date);
        }
        if (isNaN(date.getTime())) return '';

        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .slice(0, 16);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const processedData = {
            ...formData,
            start: new Date(formData.start).toISOString(),
            end: new Date(formData.end).toISOString()
        };

        if (event) {
            onUpdate({ ...processedData, id: event.id });
        } else {
            onAdd(processedData);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>{event ? 'Etkinliği Düzenle' : 'Yeni Etkinlik'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Başlık</label>
                        <input
                            type="text"
                            value={formData.title}
                            onChange={(e) => setFormData({...formData, title: e.target.value})}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Açıklama</label>
                        <textarea
                            value={formData.description}
                            onChange={(e) => setFormData({...formData, description: e.target.value})}
                        />
                    </div>

                    <div className="form-group">
                        <label>Başlangıç</label>
                        <input
                            type="datetime-local"
                            value={formData.start}
                            onChange={(e) => setFormData({...formData, start: e.target.value})}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Bitiş</label>
                        <input
                            type="datetime-local"
                            value={formData.end}
                            onChange={(e) => setFormData({...formData, end: e.target.value})}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={formData.allDay}
                                onChange={(e) => setFormData({...formData, allDay: e.target.checked})}
                            />
                            Tüm Gün
                        </label>
                    </div>

                    <div className="form-group">
                        <label>Renk</label>
                        <input
                            type="color"
                            value={formData.color}
                            onChange={(e) => setFormData({...formData, color: e.target.value})}
                        />
                    </div>

                    <div className="button-group">
                        <button type="submit" className="save-button">
                            {event ? 'Güncelle' : 'Oluştur'}
                        </button>
                        {event && (
                            <button
                                type="button"
                                className="delete-button"
                                onClick={() => onDelete(event.id)}
                            >
                                Sil
                            </button>
                        )}
                        <button type="button" className="cancel-button" onClick={onClose}>
                            İptal
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EventModal;