import React, { useState } from 'react';

const QUICK_ADD_AMOUNTS = [
    { amount: 200, label: '200ml (Küçük Bardak)' },
    { amount: 300, label: '300ml (Orta Bardak)' },
    { amount: 500, label: '500ml (Şişe)' }
];

const WaterIntakeModal = ({
                              isOpen,
                              onClose,
                              date,
                              onAddWater,
                              currentAmount,
                              goal
                          }) => {
    const [customAmount, setCustomAmount] = useState('');
    const progress = Math.min((currentAmount / goal) * 100, 100);

    const handleQuickAdd = (amount) => {
        onAddWater(date, amount);
    };

    const handleCustomAdd = (e) => {
        e.preventDefault();
        const amount = parseInt(customAmount);
        if (amount > 0) {
            onAddWater(date, amount);
            setCustomAmount('');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} onClick={onClose}>
            <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title d-flex align-items-center">
                            <i className="bi bi-droplet me-2"></i>
                            Su Tüketimi - {date.toLocaleDateString('tr-TR')}
                        </h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-4">
                            <div className="d-flex justify-content-between mb-2">
                                <span>Günlük İlerleme</span>
                                <span>{currentAmount}ml / {goal}ml</span>
                            </div>
                            <div className="progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progress}%` }}
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <h6 className="mb-3">Hızlı Ekle</h6>
                            <div className="d-grid gap-2">
                                {QUICK_ADD_AMOUNTS.map(({ amount, label }) => (
                                    <button
                                        key={amount}
                                        onClick={() => handleQuickAdd(amount)}
                                        className="btn btn-outline-primary d-flex align-items-center justify-content-center"
                                    >
                                        <i className="bi bi-droplet me-2"></i>
                                        {label}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="mb-4">
                            <h6 className="mb-3">Özel Miktar</h6>
                            <form onSubmit={handleCustomAdd} className="d-flex gap-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={customAmount}
                                    onChange={(e) => setCustomAmount(e.target.value)}
                                    placeholder="Miktar (ml)"
                                    min="1"
                                    max="2000"
                                />
                                <button type="submit" className="btn btn-primary">
                                    Ekle
                                </button>
                            </form>
                        </div>

                        <div className="alert alert-info d-flex align-items-center">
                            <i className="bi bi-droplet me-2"></i>
                            <div>
                                {progress >= 100
                                    ? "Harika! Bugünkü su tüketimi hedefinize ulaştınız!"
                                    : `Hedefinize ulaşmak için ${Math.round(goal - currentAmount)}ml daha su içmelisiniz`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WaterIntakeModal;