import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import Accessibility from "highcharts/modules/accessibility";

Exporting(Highcharts);
ExportData(Highcharts);
Accessibility(Highcharts);

export default class extends Controller {
    static targets = ["submitButton"];

    submit(event) {
        event.preventDefault();
        const formData = new FormData(this.element);
        const searchParams = new URLSearchParams();

        formData.forEach((value, key) => {
            searchParams.append(key, value);
        });

        Rails.ajax({
            url: `${this.element.action}?${searchParams.toString()}`,
            type: this.element.method || "GET",
            success: (response) => {
                this.createQuestionCharts(response);
            },
            error: (err) => {
                console.error("Form submission error:", err);
            }
        });
    }

    createQuestionCharts(data) {
        const chartContainer = document.querySelector('[data-highcharts-target="dynamicQuestionPieChart"]');

        // Önceki grafiklerin temizlenmesi
        while (chartContainer.firstChild) {
            chartContainer.removeChild(chartContainer.firstChild);
        }

        if (!data || !data.question_completion_data) {
            console.warn("No data available for charts.");
            return;
        }

        // Her bir soru için grafik oluşturma
        Object.keys(data.question_completion_data).forEach((questionTitle) => {
            const questionData = data.question_completion_data[questionTitle];

            // Soru için grafik wrapper'ı oluştur
            const wrapperDiv = document.createElement("div");
            wrapperDiv.style.display = "flex"; // Yan yana düzenleme
            wrapperDiv.style.justifyContent = "space-around";
            wrapperDiv.style.flexWrap = "wrap"; // Taşma durumunda yeni satıra geç
            wrapperDiv.style.marginBottom = "50px";

            // Eğer sorunun doğru bir şıkkı varsa Doğru/Yanlış grafik oluştur
            if (questionData["Has Correct Option"]) {
                const correctIncorrectChartDiv = document.createElement("div");
                correctIncorrectChartDiv.style.flex = "1 1 45%"; // Genişlik %45
                correctIncorrectChartDiv.style.margin = "10px";

                wrapperDiv.appendChild(correctIncorrectChartDiv);
                this.renderCorrectIncorrectPieChart(correctIncorrectChartDiv, questionTitle, questionData);
            }

            // Cevap Yüzdeleri grafiği her durumda oluşturulur
            const percentageChartDiv = document.createElement("div");
            percentageChartDiv.style.flex = "1 1 45%"; // Genişlik %45
            percentageChartDiv.style.margin = "10px";

            wrapperDiv.appendChild(percentageChartDiv);
            this.renderAnswerPercentagePieChart(percentageChartDiv, questionTitle, questionData);

            chartContainer.appendChild(wrapperDiv);
        });
    }

    renderCorrectIncorrectPieChart(chartDiv, questionTitle, questionData) {
        Highcharts.chart(chartDiv, {
            chart: {
                type: "pie"
            },
            title: {
                text: `${questionTitle} - Doğru/Yanlış Oranı`
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b> ({point.y})"
            },
            series: [{
                name: "Cevaplar",
                colorByPoint: true,
                data: [
                    { name: "Doğru Cevap", y: questionData["Doğru Cevap"] },
                    { name: "Yanlış Cevap", y: questionData["Yanlış Cevap"] }
                ]
            }],
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}: {point.percentage:.1f} %"
                    }
                }
            }
        });
    }

    renderAnswerPercentagePieChart(chartDiv, questionTitle, questionData) {
        const percentageData = Object.keys(questionData["Cevap Yüzdeleri"]).map((answerText) => {
            return {
                name: answerText,
                y: questionData["Cevap Yüzdeleri"][answerText]
            };
        });

        Highcharts.chart(chartDiv, {
            chart: {
                type: "pie"
            },
            title: {
                text: `${questionTitle} - Cevap Yüzdeleri`
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b> ({point.y})"
            },
            series: [{
                name: "Cevap Yüzdeleri",
                colorByPoint: true,
                data: percentageData
            }],
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}: {point.percentage:.1f} %"
                    }
                }
            }
        });
    }
}
