import React, { useState } from 'react';
import WaterIntakeModal from './WaterIntakeModal';
import './WaterTrackerCalendar.css';

const WaterTrackerCalendar = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [waterData, setWaterData] = useState({});

    const DEFAULT_GOAL = 2000; // 2L in ml

    const handleAddWater = (date, amount) => {
        const dateStr = date.toISOString().split('T')[0];
        setWaterData(prev => ({
            ...prev,
            [dateStr]: (prev[dateStr] || 0) + amount
        }));
    };

    const getProgressColor = (percentage) => {
        if (percentage >= 80) return 'text-green-500';
        if (percentage >= 50) return 'text-yellow-500';
        return 'text-red-500';
    };

    const getTodayWater = () => {
        const today = new Date().toISOString().split('T')[0];
        return waterData[today] || 0;
    };

    const getWaterProgress = (dateStr) => {
        const amount = waterData[dateStr] || 0;
        return Math.min((amount / DEFAULT_GOAL) * 100, 100);
    };

    const renderDayContent = (date) => {
        const dateStr = date.toISOString().split('T')[0];
        const progress = getWaterProgress(dateStr);

        return (
            <div className="water-day-content">
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDate(date);
                        setShowModal(true);
                    }}
                    className="water-indicator"
                >
                    <Droplet className={`${getProgressColor(progress)} transition-colors`} />
                    <div className="water-progress">
                        <div
                            className={`water-progress-bar ${getProgressColor(progress)}`}
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="water-tracker">
            <Card className="water-summary">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                        <Droplet className="h-5 w-5 text-blue-500" />
                        <h2 className="text-lg font-semibold">Today's Water Intake</h2>
                    </div>
                    <button
                        onClick={() => {
                            setSelectedDate(new Date());
                            setShowModal(true);
                        }}
                        className="add-water-btn"
                    >
                        Add Water
                    </button>
                </div>

                <Progress
                    value={getWaterProgress(new Date().toISOString().split('T')[0])}
                    className="h-2 mb-2"
                />

                <div className="flex justify-between text-sm">
                    <span>{getTodayWater()}ml</span>
                    <span>{DEFAULT_GOAL}ml goal</span>
                </div>
            </Card>

            {showModal && selectedDate && (
                <WaterIntakeModal
                    isOpen={showModal}
                    onClose={() => {
                        setShowModal(false);
                        setSelectedDate(null);
                    }}
                    date={selectedDate}
                    onAddWater={handleAddWater}
                    currentAmount={waterData[selectedDate.toISOString().split('T')[0]] || 0}
                    goal={DEFAULT_GOAL}
                />
            )}

            <div className="mt-4">
                {/* Bu component, ana Calendar component'ine entegre edilecek */}
                {/* renderDayContent fonksiyonu Calendar'a prop olarak geçilecek */}
            </div>
        </div>
    );
};

export default WaterTrackerCalendar;