import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import WaterIntakeModal from './WaterTracking/WaterIntakeModal';
import { useWaterTracker } from '../../hooks/useWaterTracker';
import EventModal from './EventModal';
import { useCalendarEvents } from '../../hooks/useCalendarEvents';
import './Calendar.css';

const Calendar = () => {
    const {
        events,
        isLoading,
        error,
        handleEventAdd,
        handleEventUpdate,
        handleEventDelete
    } = useCalendarEvents();

    const {
        waterData,
        isLoading: waterLoading,
        error: waterError,
        handleAddWater,
        getWaterProgress,
        getTodayWater,
        getProgressColor,
        DEFAULT_GOAL
    } = useWaterTracker();

    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [showWaterModal, setShowWaterModal] = useState(false);

    const handleExportICS = () => {
        window.location.href = '/calendar_events/export_ics';
    };

    const handleDateSelect = (selectInfo) => {
        setSelectedDate(selectInfo);
        setShowModal(true);
    };

    const handleEventClick = (clickInfo) => {
        setSelectedEvent(clickInfo.event);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedEvent(null);
        setSelectedDate(null);
    };

    const handleAdd = async (eventData) => {
        try {
            await handleEventAdd(eventData);
            handleModalClose();
        } catch (error) {
            console.error('Event eklenirken hata:', error);
            handleModalClose();
        }
    };

    const handleUpdate = async (eventData) => {
        try {
            await handleEventUpdate(eventData);
            handleModalClose();
        } catch (error) {
            console.error('Event güncellenirken hata:', error);
            handleModalClose();
        }
    };

    const handleDelete = async (eventId) => {
        try {
            await handleEventDelete(eventId);
            handleModalClose();
        } catch (error) {
            console.error('Event silinirken hata:', error);
            handleModalClose();
        }
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div className="fc-event-content">
                <div className="fc-event-title">{eventInfo.event.title}</div>
                {eventInfo.event.extendedProps?.description && (
                    <div className="fc-event-desc">{eventInfo.event.extendedProps.description}</div>
                )}
            </div>
        );
    };

    useEffect(() => {
        // Initial load durumunu kontrol et
        if (!error && !waterError) {
            console.log('Calendar initial state:', { events, waterData });
        }
    }, []);

    if (isLoading || waterLoading) {
        return (
            <div className="calendar-loading">
                <div className="loading-spinner"></div>
                <p>Yükleniyor...</p>
            </div>
        );
    }

    if (error || waterError) {
        return (
            <div className="calendar-error">
                <p>Bir hata oluştu.</p>
                <button
                    onClick={() => window.location.reload()}
                    className="btn btn-primary"
                >
                    Yenile
                </button>
            </div>
        );
    }

    return (
        <div className="calendar-container">
            <div className="mb-4">
                <div className="d-flex justify-content-end mb-3">
                    <button
                        onClick={handleExportICS}
                        className="btn btn-primary"
                    >
                        Takvimi Dışa Aktar (ICS)
                    </button>
                </div>

                <div className="card mb-4">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title d-flex align-items-center m-0">
                                <i className="bi bi-droplet me-2"></i>
                                Günlük Su Tüketimi
                            </h5>
                            <button
                                onClick={() => {
                                    setSelectedDate(new Date());
                                    setShowWaterModal(true);
                                }}
                                className="btn btn-primary"
                            >
                                Su Ekle
                            </button>
                        </div>
                        <div className="progress mb-2" style={{height: "0.5rem"}}>
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${getWaterProgress(new Date().toISOString().split('T')[0])}%`
                                }}
                            />
                        </div>
                        <div className="d-flex justify-content-between small">
                            <span>{getTodayWater()}ml</span>
                            <span>{DEFAULT_GOAL}ml hedef</span>
                        </div>
                    </div>
                </div>
            </div>

            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={events}
                select={handleDateSelect}
                eventDisplay="auto"
                eventClick={handleEventClick}
                eventContent={renderEventContent}
                eventInteractive={true}
                displayEventTime={true}
                displayEventEnd={true}
                forceEventDuration={true}
                locale="tr"
                buttonText={{
                    today: 'Bugün',
                    month: 'Ay',
                    week: 'Hafta',
                    day: 'Gün',
                    list: 'Liste'
                }}
                dayCellContent={(date) => {
                    const dateStr = date.date.toISOString().split('T')[0];
                    const waterAmount = waterData[dateStr] || 0;
                    const glassCount = Math.floor(waterAmount / 200); // Her 200ml bir bardak

                    return (
                        <div>
                            {/* Gün numarası */}
                            <div className="d-flex justify-content-end mb-2">
                                <span className="day-number">{date.dayNumberText}</span>
                            </div>

                            {/* Bardak simgeleri */}
                            {glassCount > 0 && (
                                <div
                                    className="water-glasses-container"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedDate(date.date);
                                        setShowWaterModal(true);
                                    }}
                                    style={{cursor: 'pointer'}}
                                    title={`${waterAmount}ml`}
                                >
                                    {[...Array(Math.min(glassCount, 5))].map((_, index) => (
                                        <i key={index} className="fas fa-glass-whiskey text-primary me-1"
                                           style={{fontSize: '12px'}}></i>
                                    ))}

                                    {glassCount > 5 && <small className="text-primary">+{glassCount - 5}</small>}
                                </div>
                            )}


                            {/* Progress bar */}
                            <div className="progress mt-1" style={{height: '2px'}}>
                                <div
                                    className={`progress-bar bg-${getProgressColor(getWaterProgress(dateStr))}`}
                                    role="progressbar"
                                    style={{width: `${getWaterProgress(dateStr)}%`}}
                                />
                            </div>
                        </div>
                    );
                }}
            />

            {showModal && (
                <EventModal
                    isOpen={showModal}
                    onClose={handleModalClose}
                    event={selectedEvent}
                    selectedDate={selectedDate}
                    onAdd={handleAdd}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                />
            )}

            {showWaterModal && selectedDate && (
                <WaterIntakeModal
                    isOpen={showWaterModal}
                    onClose={() => {
                        setShowWaterModal(false);
                        setSelectedDate(null);
                    }}
                    date={selectedDate}
                    onAddWater={handleAddWater}
                    currentAmount={waterData[selectedDate.toISOString().split('T')[0]] || 0}
                    goal={DEFAULT_GOAL}
                />
            )}

            <div className="calendar-legend">
                <div className="legend-item">
                    <span className="legend-color personal-event"></span>
                    <span className="legend-label">Kişisel</span>
                </div>
                <div className="legend-item">
                    <span className="legend-color leave-event"></span>
                    <span className="legend-label">İzin</span>
                </div>
                <div className="legend-item">
                    <span className="legend-color shift-event"></span>
                    <span className="legend-label">Vardiya</span>
                </div>
                <div className="legend-item">
                    <span className="legend-color holiday-event"></span>
                    <span className="legend-label">Tatil</span>
                </div>
            </div>
        </div>
    );
};

export default Calendar;