// EmployeeInfo.js
import React, { useMemo } from 'react';

const EmployeeInfo = ({ user, isSelected, onSelect, weekShifts }) => {
    const { totalNetHours, totalGrossHours } = useMemo(() => {
        let netHours = 0;
        let grossHours = 0;

        if (weekShifts && weekShifts[user.id]) {
            Object.values(weekShifts[user.id]).forEach(dayShifts => {
                dayShifts.forEach(shift => {
                    const calculatedShiftTime = parseFloat(shift.calculated_shift_time) || 0;
                    netHours += calculatedShiftTime;
                    grossHours += calculatedShiftTime;

                    if (shift && typeof shift.slack_time === 'number') {
                        grossHours += shift.slack_time
                    }
                });
            });
        }

        return {
            totalNetHours: netHours.toFixed(2),
            totalGrossHours: grossHours.toFixed(2)
        };
    }, [weekShifts, user.id]);

    return (
        <div className="employee-info">
            <div className="avatar">
                {user.avatar && typeof user.avatar === "string" ? (
                    <img src={user.avatar} alt={user.first_name} />
                ) : user.first_name ? (
                    user.first_name.charAt(0) + user.last_name.charAt(0)
                ) : (
                    "U"
                )}
            </div>
            <div className="employee-details">
                <div className="employee-name">
                    {user.first_name} {user.last_name}
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => onSelect(user.id)}
                        className="employee-checkbox"
                    />
                </div>
                <div className="employee-job-position">
                    {user.job_position || ""}
                </div>
                <div className="employee-subinfo">
                    {`SC: ${user.client_id || ""}`} | Net: {totalNetHours}s | Brüt: {totalGrossHours}s
                </div>

            </div>
        </div>
    );
};

export default EmployeeInfo;